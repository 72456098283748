.calls-alert-wrap {
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 13;

  .alert-component {
    left: auto !important;
  }

  &_show {
    opacity: 1;
    visibility: visible;
  }
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.info-bar-center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-app-region: drag !important;
}

.calls-traffic-light {
  position: relative;
  z-index: 1;

  &-win {
    position: absolute;
    right: 8px;
  }
}

.info-bar-item {
  position: absolute;
  width: 100%;
  top: 0;
  height: 40px;
  min-height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  -webkit-app-region: drag;
  transition: 0.1s;
  user-select: none;
  z-index: 13;

  &:not(.is-web) {
    position: relative;
  }

  > div {
    -webkit-app-region: none;
  }

  &.is-hide {
    top: -40px;
  }

  &.is-group {
    &.is-web {
      border-bottom: unset;
    }
  }

  &-m {
    .call-time {
      margin-left: 69px;
    }
  }

  .conference-info {
    font-size: 13px;
    line-height: 18px;
    width: auto;
  }

  .info-bar-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    &-win {
      margin-right: 92px;
    }
  }

  &-sm {
    .info-bar-right {
      display: none;
    }

    padding-left: 12px;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    margin-top: -40px;
  }

  .view-chage-button {
    @include theme-aware('background', 'bg-body');
    border-radius: 8px;
    overflow: hidden;
  }
}

.title-component {

  .title {
    text-align: center;
    color: $color-default-white;
    font-size: 18px;
    line-height: 24px;
  }

  .subtitle {
    color: $color-grey--60;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}

.user-calling {
  @include theme-aware('color', 'text-100');
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.right-bar-list-comments {
  position: relative;
  height: 100%;
}
.comments-right-bar {
  @include theme-aware('background', 'bg-body');
  visibility: hidden;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  position: relative;
  border-left: 1px solid;
  @include theme-aware('border-color', 'stroke-opacity');
  @include theme-aware('color', 'text-100');
  z-index: 1;

  .rp-import-load {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .participants-right-bar-content{

    .accordion{
      .header-left{
        margin-right: 0;
        padding-left: 12px;

        .component-text{
          margin: 0;
        }
      }
    }

  }

  .divider {
    width: 100%;
  }

  &-content {
    position: absolute;
    width: 100%;
    height: calc(100% - 120px);
    left: 0;
    top: 120px;
  }

  .user-host {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    @include theme-aware('background', 'text-80');
    border-radius: 6px;

    svg path {
      fill: $white;
    }
  }

}

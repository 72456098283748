@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.waiting-entry {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  .btn--white span {
    color: unset !important;
  } 
}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.page-error {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  .floating-image {
    width: 1116px;
    height: 3330px;
    position: absolute;
    right: 60%;
    zoom: 0.5;
  }

  .title-text {
    // TODO добавить новоую переменную font: $title
    font: $bold 40px/52px $main-family;
    // ---------------------
  }

  &-content {
    width: 560px;
  }
  
  &--header {
    @include theme-aware("color", "text-80");
  }


  .link-example span {
    justify-content: center;
  }
}
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.circle-button {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid;
  width: 32px;
  height: 32px;
  overflow: hidden;
  @include theme-aware('border-color', 'stroke-opacity');
  @include theme-aware('background', 'bg-body');

  .tooltip__wrap {
    width: 100%;
    height: 100%;
  }

  &.is-active {
    .circle-button-content {
      @include theme-aware('background', 'bg-hover');
    }
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @include theme-aware('background', 'bg-body');
  }

  &:hover {
    .circle-button-content {
      @include theme-aware('background', 'bg-hover');
    }
  }

  &-smooth {
    transition: $new-transition-settings;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      @include theme-aware('fill', 'text-80');
    }
  }
}

.list-item__right {
  .circle-button {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.participant-view-bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0;
  position: relative;
  user-select: none;
  //margin-top: -40px;

  .is-small-layout-hide {
    display: none;
  }

  .you-share-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include theme-aware('background-color', 'bg-second');

    svg {
      path {
        @include theme-aware('fill', 'text-80');
      }
    }
  }

  .participant-view-bottom__content {
    display: flex;
    flex-direction: column;

    .video.full-video {
      border-radius: unset;

      video {
        object-fit: cover;
        object-position: bottom;
        border-radius: 0;
      }
    }

  }

  .caller-name {
    display: none;
  }

  .project-avatar.project-avatar--lg {
    width: 240px;
    height: 240px;
    margin-bottom: 24px;
  }

  .participant-view-bottom-name{
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
    @include theme-aware('color', 'text-100');

    &-sm {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &.is_group {
    //margin-top: 0;

    .resize-view-center {
      align-items: flex-start;
    }
  }

  &.is-small {

    .avatar--full {
      width: 96px;
      height: 96px;
    }

    .participant-view-bottom__content {
      height: calc(100% - 64px);
    }

    .participant-view-bottom-name{
      display: none;
    }
  }

  &.is-x-small {
    display: none;
  }

  .maximize-is-small {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $new-transition-settings;
    background: $bg-popup;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    z-index: 13;

    svg path {
      fill: $white;
    }

    .component-text {
      color: $white !important;
    }

  }

  &:hover {
    .maximize-is-small {
      opacity: 1;
      visibility: visible;
    }
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    overflow: hidden;

    .participant-view__content {
      z-index: 10;
      transition: unset;
    }
  }
}

@media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) {
  .center-video video{
    border: none !important;

    border-radius: 8px;
  }
}}

.participant-view-bottom__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;

  &.full {
    height: 100%;
  }

}

.resize-view-center {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .view-user-data {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.layout-grid-wrapper {
  padding: 64px 12px 76px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

@import "../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors";

.waiting-select-devices {
    margin-top: 24px;
    width: 100%;

    &-buttons {
        width: calc(33.33333% - 5px);
        
        .btn__title {
            text-align: left;
        }
    }
}

.active-blue {
    .list-item.is-active {
        .list-item__title {
            color: $action;
        }

        svg path {
            fill: $action;
        }
    }
}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.settings-context-menu {

  &,
  &--more {
    max-height: calc(100vh - 100px) !important;

    .is-active {
      background-color: unset;
      color:$action !important;
      .icon-wrapper  svg path {
        fill: $action !important;
      }
    }
  }
}

#context-root:has(.settings-context-menu) {
  .context-submenu {
    min-height: unset;
    max-height: calc(100vh - 100px);
  }

  .is-active {
    background-color: unset;
    &:hover {
      @include theme-aware("background", "bg-hover")
    }
    .list-item__title {
      color: $action !important
    }
  }

}
@import '~@clout-team/web-components/build/assets/styles/main-colors';

$buttons-container-height: 96px;
$header-height: 100px;

.display-media {
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;

  &-header {
    -webkit-app-region: drag;
    height: 36px;
    padding: 6px;
    display: flex;
    align-items: flex-start;

    &-win {
      justify-content: flex-end;
    }
  }

  &-content {
    height: 100%;
    padding: 0 32px;

    .component-grid-item.size-120 {
      width: 176px;
      max-width: 176px;
    }

    .component-text.text-type-selected {
      color: $white !important;
      background-color: $action !important;
    }
  }

  &-title {
    padding: 32px 28px;
    -webkit-app-region: drag
  }

  &-buttons {
    height: 96px;
    min-height: 96px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    justify-content: space-between;
  }

}

.display-media-buttons__container {
  margin-left: auto;
}

.display-media-content__scroll-section {
  max-height: calc(100vh - $buttons-container-height - $header-height);
}
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.video-loading {
  position: absolute;
  z-index: 11;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @include theme-aware('background', 'bg-body');

  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }
}

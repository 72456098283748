
.change-host-button {
  padding: 8px 16px;
}

.change-host-list {
  max-height: 280px;

  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.comment-counters {
  & .is-badge {
    position: absolute;
  }
}

.action-bar {
  padding: 12px;
  display: flex;
  align-items: center;
  transition: 0.1s;
  width: 100%;
  height: 64px;
  min-height: 64px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    @include theme-aware('background-color', 'bg-body');
    opacity: 0.95;
  }

  .disable-btn {
    opacity: 0.32;
    cursor: auto;

    &:hover {
      background: unset !important;
    }
  }

  &.is-mobile {
    .hide-button {
      display: none;
    }
  }

  .person-add-button {
    margin-left: 8px;
    position: relative;

    .users-count {
      display: none;
      position: absolute;
      top: 1px;
      left: 23px;
      font-size: 13px;
      line-height: 18px;
      @include theme-aware('color', 'text-80');
    }
  }

  .component-icon-button-red {
    svg {
      transition: $new-transition-settings;
    }

    &:hover {
      svg {
        opacity: 0.6;
      }
    }
  }

  .record-btn {

    &:not(:disabled):hover {
      background-color: $color-black-call-hover;
    }

    &.active {
      background-color: $white;
      color: $color-black-call;
      svg {
        path {
          fill: $color-black-call;
        }
      }

      &:not(:disabled):hover {
        background-color: $color-white;
        color: $color-black-call;
        svg {
          path {
            fill: $color-black-call;
          }
        }
      }
    }
  }

  .btn--black {
    .icon {
      width: 24px;
      height: 24px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .is-small-action-bar {
    display: none;
    -webkit-app-region: none;
  }

  &.is-small {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 13;
    padding: 12px;
    transition: unset;

    .action-bar-left, .action-bar-center, .action-bar-right {
      display: none;
    }

    .is-small-action-bar {
      display: flex;
    }
  }

  &.hide {
    //opacity: 0;
    visibility: hidden;
    margin-bottom: -72px;
  }

  &.static {
    position: static;
  }

  .btn__title {
    font-weight: 700;
  }
}

.action-bar-left {
  width: 300px;
}

.action-bar-center {
  width: calc(100% - 600px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .btn--red {
    svg {
      position: relative;
    }
    &:hover {
      &:before {
        background: $text-100 !important;
        opacity: 0.32;
      }
    }
  }

  .btn--black {
    &:hover {
      background-color: $color-black-call-hover !important;
    }
  }

}

.comments-button {
  min-width: none ;
}
.icon-with-counters {
  .dot-element {
    position: absolute;
    border-radius: 50px;
    background-color: $error;
    width: 12px;
    height: 12px;
    right: -1px;
    top: -1px;
  }
}
.unread-rounded {
  position: absolute;
  top: -4px;
  right: -4px;
}
.action-bar-right {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-end;
}

.is-small-action-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
}

@media screen and (max-width: 500px) {
  .action-bar {
    flex-wrap: wrap;
  }

  .action-bar-left, .action-bar-center, .action-bar-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

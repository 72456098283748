@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.participant-card {
  position: relative;
  min-width: 160px;
  min-height: 90px;
  width: 100%;
  height: 100%;
  user-select: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  audio {
    display: none;
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    z-index: 11;
  }

  &__contain-video {
    .participant-card__content {
      &:before {
        @include theme-aware('background', 'bg-body');
      }
    }
    video {
      object-fit: contain;
      object-position: center;
    }
  }

  &__hide-video {
    video {
      display: none;
    }
  }

  &__reverse-video {
    video {
      transform: scaleX(-1);
    }
  }

  &__hide {
    display: none;
  }

  &__content {
    width: 100%;
    height: 100%;
    @include theme-aware('background', 'bg-body');
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      @include theme-aware('background', 'surface-1');
    }

    &-status {
      position: relative;
      overflow: hidden;
    }

    .center-call-status {
      position: absolute;
      top: -48px;
      width: 100%;
    }
  }

  &:before, &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    transition: transform 0.1s;
    z-index: 0;
    transform: scale(0.9);
  }

  &:after {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    opacity: 0.4;
    background: $action;
    border-radius: 12px;
  }

  &:before {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: $action;
    border-radius: 8px;
  }

  &__speaking {
    &:before, &:after {
      transform: scale(1);
    }
  }

  &__name {
    position: absolute;
    left: 2px;
    bottom: 2px;
    height: 20px;
    @include theme-aware('color', 'text-100');
    border-radius: 6px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0 4px;
    z-index: 11;
    width: auto;
    max-width: calc(100% - 8px);
    overflow: hidden;
    transition: 0.1s;

    .sky-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0.8;
      @include theme-aware('background', 'bg-body');
    }

    &.is-hide {
      opacity: 0;
      visibility: hidden;
    }

    &-share {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
        path {
          @include theme-aware('fill', 'text-80');
        }
      }
    }

    &-host {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 0 0 6px;
      @include theme-aware('background', 'text-80');
      margin-right: 4px;
      width: 24px;
      height: 20px;
      margin-left: -4px;

      svg {
        width: 16px;
        height: 16px;

        path {
          @include theme-aware('fill', 'bg-body');
        }
      }
    }

    &-mic {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: $color-red;
        }
      }
    }

    &-user {
      flex: 1;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  &__button-layout {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 11;
    transition: opacity $new-transition-settings;
  }

  &__unpin {
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 13;
    border-radius: 8px;
    @include theme-aware('background-color', 'bg-second');
  }

  &:hover {
    .participant-card__button-layout {
      opacity: 1;
      visibility: visible;
    }
  }

  .resize-text {
    font: $header-bold;
    font-weight: 700;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
  }

  &:not(.size-md), &:not(size-lg) {
    .participant-card__unpin {
      left: 8px;
      top: 8px;
    }
  }

  &.size-md {
    .avatar--xxl {
      width: 96px;
      height: 96px;
    }
    .resize-text {
      font-size: 40px;
      line-height: 52px;
    }
  }

  &.size-lg {
    .avatar--xxl {
      width: 120px;
      height: 120px;
    }
    .resize-text {
      font-size: 68px;
      line-height: 92px;
    }
  }

  .pr-debug {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px;
    z-index: 12;
    border-radius: 8px;
    background: $bg-popup;
    color: $white;
    font: $metadata;
  }

}

@media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) {
  .participant-card video{
    border: none !important;
    border-radius: 8px;
  }
}}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.plug-block {
  @include theme-aware('background', 'surface-1');
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 40px;
  // align-items: center;
  justify-content: center;
  .meeting-title-text{
    font-size: 40px;
    line-height: 52px;
  }

  &.is-align-center {
    align-items: center;
    padding: 40px;

    .rate-us-wrapper {
      display: none;
    }

  }
}

.send-call {
  margin-top: 50px;
}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.hotkey {
  font-size: 13px;
  line-height: 18px;
  color: $white;
  display: flex;
  align-items: center;

  &__icon {
    // у нас всегда на черном тултипе
    background-color: rgba(255, 255, 255, 0.08);
    padding: 2px 4px;
    border-radius: 4px;
    color: $white;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    height: 20px;
    min-height: 20px;
  }
}

@import "../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette";
@import "../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors";

.waiting-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  &-left {
    width: 100%;
    max-width: 740px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &-right {
    min-width: 448px;
    width: 448px;
    display: flex;
    align-items: center;
    padding: 24px;

    &-content {
      padding: 0 16px;
      width: 100%;

      >.layout {
        width: 100%;
      }
    }
  }

  .page-content-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-layout {
    justify-content: center;
    width: 100%;
  }

  .new-meeting-button {
    margin-top: 16px;
  }

  .clickable-meet-title {
    border-radius: 6px;
    transition: 0.2s;
    cursor: pointer;
    padding: 0 4px;
    display: inline-flex;
    margin: 0 auto;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    &:hover {
      @include theme-aware("background-color", "bg-hover");
    }

    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;

      path {
        @include theme-aware("fill", "text-80");
      }
    }
  }

  .content-edit-title {
    border: unset;
    font-size: 24px;
    line-height: 32px;
    display: inline-flex;
    padding: 0 4px;
    max-height: 64px;
    overflow: hidden;
    text-align: center;

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: unset;
      outline: unset;
    }

    &:empty:before {
      content: attr(placeholder);
      @include theme-aware("color", "text-60");
      font-weight: 400;
    }

  }

  .waiting-page-privacy {
    margin-top: 24px;
  }
}

.dialog-permissions-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .dialog-permissions-info {
    position: absolute;
    right: 132px;
    top: 0;
    z-index: 12000;

    &-arrow {
      width: 60px;
      height: 336px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-icon {
      width: auto;
      height: 64px;

      img {
        height: 100%;
      }
    }
  }
}

.icon-blue {
  svg {
    path {
      fill: $action;
    }
  }
}

.full-width {
  width: 100%;
}

.editable-layout {
  align-items: center;
  justify-content: center;
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1252px) {
  .waiting-page {
    .page-content-wrap {
      max-width: 100%;
    }
  }

  .waiting-page-left {
    max-width: calc(100% - 448px - 16px);
  }

  .waiting-room-video__content {
    height: 100%;
  }
}

@media screen and (max-width: 960px) {
  .waiting-page-left {
    max-width: 100%;
  }

  .waiting-page {
    &-layout.layout {
      width: 100%;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 500px) {
  .waiting-page {
    .waiting-select-devices {
      >.layout {
        flex-direction: column;
      }
    }

    &-right {
      min-width: 100%;
      width: 100%;
    }

    .waiting-select-devices {
      &-buttons {
        width: 100%;
      }
    }
  }


}

.traffic-lights {
  margin-left: 10px;
  .traffic-lights-btns-wrap, .traffic-light {
    width: 40px !important;
    height: 40px !important;

    &:hover {
      cursor: pointer;
    }
  }
}

.waiting-room {
  &.is-electron {
    margin-left: auto;
    margin-top: -24px;
    margin-right: -24px;

    // .component-page-header-button {
    //   margin-right: calc(50vw - 240px);
    //   -webkit-app-region: drag !important;
    // }

    .traffic-lights-btns-wrap, .traffic-light {
      width: 48px !important;
      height: 48px !important;

      svg {
        width: 24px;
        height: 24px
      }
    }
  }
}

.info-bar-item {
  .traffic-lights {
    margin-right: -8px;
  }
}

.traffic-light {
  border-radius: unset !important;
}
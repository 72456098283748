@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.video {
  width: 160px;
  min-width: 160px;
  height: 90px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  &.hidden {
    display: none;
  }

  .video-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 1;
    background: $bg-popup;
  }

  &.full-video {
    width: 100%;
    height: 100%;
    cursor: default;
    video {
      object-fit: contain;
    }
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &.reverse-video {
    video {
      transform: scaleX(-1);
    }
  }
}

@import "../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors";

.wating-users-component {
  .wating-users-avatars {
    position: relative;

    > span {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $bg-popup;
      color: $white;
      z-index: 11;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.send-to-email-banner{
  @include theme-aware("background", "bg-body");
  @include theme-aware("border", "stroke-opacity", "1px solid");
  border-radius: 16px;
  overflow: hidden;
  max-width: 680px;
  margin: 32px;
  &--content {
    margin: 32px;
    max-width: 360px;
  }
  &--image {
    background-image: url("../../../../assets/images/send-report.svg");
    width: 244px;
    height: 240px;
    background-repeat: no-repeat;
    position: relative;
    left: 0;
  }
}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: var(--app-height);
}

.page-layout{
  height: var(--app-height);
}

.layout {
  position: relative;
}

.react-call-wrap {
  height: 100%;
  min-height: 100%;
  position: relative;
}

svg.red {
  path {
    fill: $color-red !important;
  }
}

.web-call-time, .call-time {
  .time-space {
    width: 1px;
    height: 16px;
    @include theme-aware('background', 'stroke-opacity');
  }
}

.web-call-time {
  display: flex;
  align-items: center;
  @include theme-aware('color', 'text-100');
  border-radius: 8px;
  @include theme-aware('background', 'bg-body');
  padding-right: 8px;
}

.call-time {
  @include theme-aware('color', 'text-100');
  display: flex;
  align-items: center;

  &.electron-time {
    padding-left: 60px;
  }
}

.btn--sm.btn--icon {
  > svg {
    width: 16px;
    height: 16px;
  }
}

.component-dialog {
    // TODO Разобрать почему Dialog Title не применял inverted-theme-aware
    .component-dialog-title-title {
      color: var(--text-100)
    }
    &.inverted-theme-aware {
      .component-dialog-title-title {
        color: var(--text-100) !important
      }
    }
}

.dialog-rounded-corners  {
  .component-dialog__inner {
    border-radius: 12px;
  }
}

.rounded-corners-6 {
  border-radius: 6px;

  &:before {
    border-radius: 6px;
  }
}

.rounded-corners-8 {
  border-radius: 8px;

  &:before, input {
    border-radius: 8px;
  }
}

.rounded-corners-12 {
  border-radius: 12px;
}

.form-field.inverted-color {
  input {
    @include theme-aware('background', 'bg-second');
  }
}

.outgoing-call {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .alert-component {
    z-index: 999;

    .message-bar--close {
      display: none;
    }
  }

  .is-show-close {
    .alert-component {
      .message-bar--close {
        display: inline-flex;
      }
    }
  }
}

.buttons-wrap {
  display: flex;
  align-items: center;

  .space {
    flex-grow: 1;
  }
}

.page-index {
  height: 100%;
  display: flex;
  width: 100%;

  .change-xsmall {
    display: none;
  }

  .project-avatar__avatar {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.is-small {
    .is-small-action-bar:not([draggable=true]) {
      .component-icon-button {
        -webkit-app-region: none;
      }
    }

    .change-xsmall:not([draggable=true]) {
      -webkit-app-region: none;
    }

    .change-xsmall-wrap {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      z-index: 2;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      -webkit-app-region: none;
    }

    .change-xsmall {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: $new-transition-settings;

      &:hover {
        @include theme-aware('background', 'bg-hover');
      }

      svg {
        transform: rotate(180deg);

        path {
          @include theme-aware('fill', 'text-100');
        }
      }
    }
  }

  &.is-x-small {
    .change-xsmall {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  &.is-fullscreen {
    .info-bar-item {
      transition: $new-transition-settings;
      width: 100%;
      @include theme-aware('background', 'left-nav-panel');
    }

    .participant-view-top-wrap {
      padding-top: 16px;

      &.private-top-wrap {
        padding-top: 0;
      }
    }
  }

}


////////////////
.context-menu__list {
  max-height: 316px;
  min-height: 316px;
}

.page-content-wrap {
  max-width: 1204px;
  margin: 0 auto;
  width: 100%;
}

.media-permission {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: $error;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  right: -5px;
  top: -5px;
  z-index: 1;
}

#root {
  .btn--inverted.btn--icon.btn--green.green-path path {
    fill: $color-green;
  }

  .btn--white.btn--inverted .icon .i-mono path,
  .btn--white.btn--inverted .btn__icon .i-mono path {
    @include theme-aware('fill', 'text-80');
  }

  .custom-btn-style {

    &.btn--white:not(.btn--inverted) {
      @include theme-aware('background', 'bg-body');
    }

    &.bg-state {
      &.btn--white:not(.btn--inverted) {
        @include theme-aware('background', 'bg-second');
      }
    }

    .icon {
      width: 24px;
      height: 24px;

      svg path {
        @include theme-aware('fill', 'text-80');
      }
    }

    &.red-icon {
      svg path {
        fill: $red-1 !important;
      }
    }

    &.btn--red {
      // border: 1px solid !important;
      @include theme-aware('border-color', 'stroke-opacity');
    }

    &.end-call-button {
      svg path {
        fill: $white !important;
      }
    }

  }

  .sky-btn {
    backdrop-filter: $skyBlur60;
    @include theme-aware('background-color', 'clout-sky');

    &.red-icon {
      svg path {
        fill: $red-1 !important;
      }
    }

    .icon {
      svg {
        path {
          @include theme-aware('border-color', 'text-80');
        }
      }
    }
  }

  .bg-custom-l {
    &.btn--inverted:not(:disabled):hover {
      // hover
    }

    &.btn--active:before {
      @include theme-aware('background', 'surface-2');
    }

    &.btn--active:hover {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        @include theme-aware('background', 'bg-hover');
      }
    }

  }

  .btn--icon.btn--xsm {
    max-height: 20px;
    min-height: 20px;
  }

  .component-text {
    &.align-center {
      text-align: center;
    }
  }
}

@mixin adaptive-style-sm() {
  .action-bar-center {
    .btn {
      max-width: 40px;
      min-width: 40px;
    }

    .btn__title {
      display: none;
    }

    .icon {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 800px) {
  @include adaptive-style-sm();
}


@mixin adaptive-style-md() {
  .users-count {
    display: block !important;
  }

  .action-bar-right {
    .btn {
      max-width: 40px;
      min-width: 40px;
    }

    .btn__title {
      display: none;
    }

    .icon {
      margin: 0 !important;
    }
  }
}

#root {
  &.md-size {
    @include adaptive-style-md();
  }

  &.sm-size {
    @include adaptive-style-md();
    @include adaptive-style-sm();
  }
}


@media screen and (max-width: 1200px) {
  @include adaptive-style-md();
}

@media screen and (max-width: 500px) {

  .participant-view-top-wrap {

    .carousel-nav {
      display: none;
    }

    &.is-group {
      padding: 10px 16px;
    }
  }

  #root {
    .custom-btn-style, .sky-btn {
      max-width: 40px;
      min-width: 40px;

      .btn__title {
        display: none;
      }

      .icon {
        margin: 0 !important;
      }
    }

    .participant-view-top-wrap.is-group.is-web {
      max-width: 100%;
      padding: 6px 12px;
      margin-top: 40px;
    }

    .action-bar-right, .conference-button-wrap {
      display: none;
    }
  }

  .component-dialog__inner {
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }



}

////////////////


// Safari
@supports (-webkit-touch-callout: none) and (not (translate: none)) {

  .participant-view-top, .video-loading, .is-small-action-bar {
    > div, > span, > button, > p, > img {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .participant-view-bottom {
    height: 100%;
  }

  .participant-view-bottom__content {
    display: contents;
  }

  .participant-view-top {
    .video video {
      border-radius: 8px;
    }
  }

  .layout {

    &.layout-row {

      &.layout-gap-xsm {
        > div, > span, > button, > p, > img {
          margin-right: 4px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.layout-gap-sm {
        > div, > span, > button, > p, > img {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.layout-gap-md {
        > div, > span, > button, > p, > img {
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.layout-gap-lg {
        > div, > span, > button, > p, > img {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.layout-gap-xlg {
        > div, > span, > button, > p, > img {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

    }

    &.layout-column {

      > div, > span, > button, > p, > img {
        margin-right: 0 !important;
      }

      &.layout-gap-xsm {
        > div, > span, > button, > p, > img {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.layout-gap-sm {
        > div, > span, > button, > p, > img {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.layout-gap-md {
        > div, > span, > button, > p, > img {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.layout-gap-lg {
        > div, > span, > button, > p, > img {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.layout-gap-xlg {
        > div, > span, > button, > p, > img {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

  }
}

@media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) {
  .waiting-room-video__video video{
    border: none !important;
    //width: calc(100% + 1px) !important;
    //height: calc(100% + 1px) !important;

    border-radius: 5px 4px 4px 5px;
  }
}}

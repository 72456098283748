@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-loading {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &-icon {
    position: absolute;
    width: 24px;
    height: 24px;
  }

  > svg {
    animation: 1s linear 0s normal none infinite running rotate;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      @include theme-aware('fill', 'text-80');
    }
  }
}

@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.pages-wrap {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 24px;
  @include theme-aware('background', 'bg-body');
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-rows: auto 21fr auto;
  &--component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    z-index: 1000;
  }

  -webkit-app-region: drag;

  & * {
    -webkit-app-region: no-drag;
  }
}

.wrapper-header {

  .component-page-header-button {
    width: auto;
  }

  &>button {
    height: fit-content;
    border-radius: 8px;
  }
  .waiting-room-logo {
    user-select: none;
    cursor: pointer;
  }
}

@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.rate-item-wrapper {
  user-select: none;
  cursor: pointer;

  .rate-item {
    background-color: rgba($color: $warning, $alpha: 0.16);
    border-radius: 50%;
    padding: 10px;
    border: 2px solid transparent;
    user-select: none;
    transition: border-color, opacity, mix-blend-mode .3s ease-in-out;

    &:hover,
    &.active {
      border-color: $warning;
    }

    &.disabled {
      mix-blend-mode: luminosity;
      opacity: .5;

      &:hover {
        border-color: transparent;
      }
    }

    &--icon {
      width: 40px;
      height: 40px;
    }
  }

  &:has(.rate-item.disabled),
  &:has(.rate-item.active) {
    cursor: default;
  }
}


.rate-us-wrapper {
  &--button {
    position: relative;
    height: 40px;
    transition: .3s ease-in-out;

    .btn {
      transition: .3s ease-in-out;

      &.hide {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .thanks-for-feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    justify-content: center;
    align-items: center;
    height: 40px;
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
    display: flex;

    &.show {
      visibility: visible;
      display: flex;
      opacity: 1;
    }
  }
}

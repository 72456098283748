@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.invite-conference-dilog {



  .list-item {
    &__avatar {
      align-self: center;
    }

    &__right path {
      fill: $success !important;
    }

    .invite-conference-banner--icon {
      @include theme-aware("color", "text-80")
    }
  }
}

@import "../../../../node_modules/@clout-team/web-components/build/assets/styles/variables.scss";
@import "../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette";
@import "../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors";

.waiting-room-video {
  width: 100%;
  height: 100%;

  &__content {
    position: relative;
    height: 416px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 8px;
    overflow: hidden;
  }

  .no-stream,
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include theme-aware('background-color', 'surface-1');
  }

  .no-stream {
    font-size: 24px;
    line-height: 32px;
    @include theme-aware('color', 'text-80');
  }

  &__video {
    &.video {
      width: 100%;
      height: 100%;
    }

    video {
      object-position: bottom;
      @include theme-aware('background-color', 'text-100');
    }
  }

  .micro-camera {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    z-index: 1;
  }

  &-loader {
    position: absolute;
    height: 6px;
    width: 100%;
    overflow: hidden;
    bottom: 0;
    left: 0;

    span {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;

      background: $action;
      width: 0;
    }
  }

  &-loader-visible {
    span {
      transition: 0.5s;
      width: 40%;
    }
  }

  &-loader-finish {
    span {
      transition: 0.3s;
      width: 100%;
    }
  }
}

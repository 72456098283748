
.unmute-message-bar {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.3s;
  visibility: hidden;
  opacity: 0;

  &-show {
    visibility: visible;
    opacity: 1;
  }
}

#conference-align {
  width: 360px;
  position: fixed;
  left: 8px;
  top: 32px;
  height: 0;
  z-index: -999;
}

.layout-user-name{
  p.component-text{
    width: calc(100% - 24px);

    span.content{
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
}
